






import { Component, Vue } from "vue-property-decorator";

// Components
import { HelpIcon } from "@components";
import { GamePage, Snake as SnakeGame } from "@components/Games";

// Utilities
import games from "@/games";

@Component({
  components: {
    GamePage,
    HelpIcon,
    SnakeGame,
  },
})
export default class Snake extends Vue {
  game = games.snake;
}
